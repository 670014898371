<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                        width="55" label="#" >
      </el-table-column>
      <el-table-column prop="name" width="241" label="问题名称">
        <template #default="scope">
          <el-tag size="mini" >{{scope.row.title}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="answer" show-overflow-tooltip min-width="241" label="问题详情">
      </el-table-column>
      <el-table-column label="操作" width="110">
        <template #header>
          <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
      >
        <el-form-item label="问题名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="产品类型" prop="category_info">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="category"
              @change="postcategory"
              v-model="ruleForm.category_info"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item label="问题详情" prop="">
          <el-input type="textarea" :rows="5" v-model="ruleForm.answer"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/productapiitem"
import {getbrandselect, postCateTree} from "@/api/common";
//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      login:false,
      drawer:false,
      category:[],
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        title:'',
        category_info:[],
        answer:'',
      },
      rules:{
        title: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        category_info: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onselect()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onselect(){
      postCateTree('').then((res)=>{
        console.log('getCateTree',res)
        this.category=res.data
      }).catch((err)=>{

      })
      getbrandselect('').then((res)=>{
        console.log('getbrandselect',res)
        this.brand=res.data.data
      }).catch((err)=>{
      })
    },
    postcategory(){
      console.log(123)
      this.$refs.cascaderHandle.dropDownVisible = false
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.getproductQueData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm= {
        title:'',
        category_info:[],
        answer:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.title=row.title
      this.ruleForm.category_info=row.category_info
      this.ruleForm.answer=row.answer
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddProductQue(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductQue(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelProductQue({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>