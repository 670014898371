//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*产品标签接口---------------------------*/
//获取产品标签列表接口
export function getproductTagData(obj) {
    return request({
        url: '/api/admin/product/tag',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品标签接口
export function postaddProductTag(obj,url) {
    return request({
        url: '/api/admin/product/tag/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品标签接口
export function posteditProductTag(obj,url) {
    return request({
        url: '/api/admin/product/tag/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品标签接口
export function postdelProductTag(obj,url) {
    return request({
        url: '/api/admin/product/tag/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
/*产品问题接口---------------------------*/
//获取产品问题列表接口
export function getproductQueData(obj) {
    return request({
        url: '/api/admin/product/question',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品问题接口
export function postaddProductQue(obj,url) {
    return request({
        url: '/api/admin/product/question/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品问题接口
export function posteditProductQue(obj,url) {
    return request({
        url: '/api/admin/product/question/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品问题接口
export function postdelProductQue(obj,url) {
    return request({
        url: '/api/admin/product/question/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
/*产品详情接口---------------------------*/
//获取产品详情列表接口
export function getproductConData(obj) {
    return request({
        url: '/api/admin/product/content',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品详情接口
export function postaddProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品详情接口
export function posteditProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品详情接口
export function postdelProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
